import React, { useState } from 'react';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import db from '../firebase.js';
import '../css/addbuildingform.css'; // 이 파일은 스타일이 있는 경우에만 필요합니다.

const AddBuildingForm = ({ onAddBuilding }) => {
  const [buildingNumber, setBuildingNumber] = useState('');
  const [lineData, setLineData] = useState([]);

  const handleAddLine = () => {
    setLineData([
      ...lineData,
      {
        line: lineData.length + 1,
        floors: 1,
        lowestFloor: 1,
        hoist: false,
        type: '',
      },
    ]);
  };

  const handleLineChange = (index, field, value) => {
    const newLineData = [...lineData];
    newLineData[index][field] = value;
    setLineData(newLineData);
  };

  const handleRemoveLine = (index) => {
    const newLineData = lineData.filter((_, i) => i !== index);
    setLineData(newLineData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!buildingNumber) {
      alert('동 번호를 입력하세요.');
      return;
    }

    // Firestore에 저장할 데이터 생성
    const buildingData = {};
    lineData.forEach((line) => {
      for (let floor = line.lowestFloor; floor <= line.floors; floor++) {
        const roomNumber = `${floor}0${line.line}`; // 예: 101호, 102호
        buildingData[roomNumber] = {
          hoist: line.hoist,
          type: line.type,
        };
      }
    });

    try {
      const docRef = doc(db, 'roomValues', buildingNumber);

      // Firestore의 기존 데이터를 가져오기
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // 기존 데이터를 병합하여 업데이트
        await updateDoc(docRef, buildingData);
      } else {
        // 문서가 없을 경우 새로 생성
        await setDoc(docRef, buildingData);
      }

      alert(`${buildingNumber}동의 데이터가 Firestore에 저장되었습니다.`);
      onAddBuilding({ buildingNumber, lineData }); // 부모 컴포넌트로 데이터 전달
      setBuildingNumber(''); // 입력 초기화
      setLineData([]);
    } catch (error) {
      console.error('Error saving building data:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div>
      <div className='bd'>
        <h2>동호수표 생성</h2>
        <input
          type='text'
          placeholder='동 번호를 입력하세요'
          value={buildingNumber}
          onChange={(e) => setBuildingNumber(e.target.value)}
        />
      </div>

      {lineData.map((line, index) => (
        <div key={index} className='line-input'>
          <h3>라인 {line.line}</h3>

          <label>최고층</label>
          <input
            type='number'
            placeholder='최고층 입력'
            value={line.floors}
            onChange={(e) =>
              handleLineChange(index, 'floors', parseInt(e.target.value))
            }
          />

          <label>최저층</label>
          <input
            type='number'
            placeholder='최저층 입력'
            value={line.lowestFloor}
            onChange={(e) =>
              handleLineChange(index, 'lowestFloor', parseInt(e.target.value))
            }
          />

          <label>
            <input
              type='checkbox'
              checked={line.hoist}
              onChange={(e) =>
                handleLineChange(index, 'hoist', e.target.checked)
              }
            />
            호이스트 있음
          </label>

          <label>타입</label>
          <input
            type='text'
            placeholder='타입 입력'
            value={line.type}
            onChange={(e) => handleLineChange(index, 'type', e.target.value)}
          />

          <button onClick={() => handleRemoveLine(index)}>라인 삭제하기</button>
        </div>
      ))}

      <button onClick={handleAddLine}>라인 추가하기</button>
      <button onClick={handleSubmit}>최종 동 추가하기</button>
    </div>
  );
};

export default AddBuildingForm;
