// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBvTbt02jQ6MBFyfl_l12fj_koKo1AQN7w',
  authDomain: 'floor-db-6ffed.firebaseapp.com',
  projectId: 'floor-db-6ffed',
  storageBucket: 'floor-db-6ffed.firebasestorage.app',
  messagingSenderId: '507476479619',
  appId: '1:507476479619:web:afde2d713a8ae086f9d5b6',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
