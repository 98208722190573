import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db from '../firebase.js'; // Firestore 초기화 파일 가져오기

const SettingPage = () => {
  const { buildingNumber, roomNumber } = useParams();
  const [roomValue, setRoomValue] = useState('');

  useEffect(() => {
    const fetchRoomValue = async () => {
      try {
        // Firestore에서 해당 건물 데이터 가져오기
        const docRef = doc(db, 'roomValues', buildingNumber);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const buildingData = docSnap.data(); // Firestore 데이터 가져오기
          setRoomValue(buildingData[roomNumber] || ''); // 해당 방 데이터 설정
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching room value:', error);
      }
    };

    fetchRoomValue();
  }, [buildingNumber, roomNumber]);

  const handleChange = (e) => {
    setRoomValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = doc(db, 'roomValues', buildingNumber);

      // Firestore의 기존 데이터를 업데이트
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const buildingData = docSnap.data();
        buildingData[roomNumber] = roomValue; // 해당 방 값 업데이트

        await updateDoc(docRef, buildingData);
      } else {
        // 문서가 없으면 새로 생성
        const newBuildingData = {
          [roomNumber]: roomValue,
        };
        await updateDoc(docRef, newBuildingData);
      }

      alert(`${buildingNumber}동 ${roomNumber}호의 속성이 저장되었습니다.`);
    } catch (error) {
      console.error('Error saving room value:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div>
      <h2>
        {buildingNumber}동 {roomNumber}호 속성 수정
      </h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>dB: </label>
          <input
            type='text'
            placeholder='dB 값 입력'
            value={roomValue}
            onChange={handleChange}
          />
        </div>
        <button type='submit'>저장하기</button>
      </form>
    </div>
  );
};

export default SettingPage;
