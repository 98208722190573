import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/sidebar.css'; // CSS 파일을 가져옵니다.

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <nav className={`sidebar ${isExpanded ? 'sidebar-menu-expanded' : 'sidebar-menu-collapsed'}`}>
      <a href="#" id="justify-icon" onClick={toggleSidebar}>
        <span className="glyphicon glyphicon-align-justify"></span>
      </a>
      <ul>
        <li className="active">
          <Link to="/" className="expandable">
            <span className="glyphicon glyphicon-home collapsed-element"></span>
            <span className={`expanded-element ${isExpanded ? 'show' : ''}`}>  생성</span>
          </Link>
        </li>
        <li>
          <Link to="/setting" className="expandable">
            <span className="glyphicon glyphicon-wrench collapsed-element"></span>
            <span className={`expanded-element ${isExpanded ? 'show' : ''}`}>수정</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/measurements" className="expandable">
            <span className="glyphicon glyphicon-cog collapsed-element"></span>
            <span className={`expanded-element ${isExpanded ? 'show' : ''}`}>설정</span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
