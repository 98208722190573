import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import db from '../firebase.js';
import '../css/styles.css';

const AllSettingsPage = ({ buildings }) => {
  const [roomValues, setRoomValues] = useState({});

  // Firestore에서 roomValues 데이터 로드
  useEffect(() => {
    const fetchRoomValues = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'roomValues'));
        const roomData = {};
        querySnapshot.forEach((doc) => {
          roomData[doc.id] = doc.data();
        });

        // roomValues 초기화: 각 건물과 호실에 대해 기본값 설정
        const initializedValues = buildings.reduce((acc, building) => {
          acc[building.buildingNumber] = acc[building.buildingNumber] || {};
          building.lineData.forEach((line) => {
            Array.from({ length: line.floors }, (_, floor) => {
              const roomNumber = (floor + 1) * 100 + line.line;
              acc[building.buildingNumber][roomNumber] =
                roomData[building.buildingNumber]?.[roomNumber] || 0;

            });
          });
          return acc;
        }, roomData);

        setRoomValues(initializedValues);
      } catch (error) {
        console.error('Error fetching room values:', error);
      }
    };

    fetchRoomValues();
  }, [buildings]);

  const handleInputChange = (buildingNumber, roomNumber, value) => {
    // 값이 숫자로 변환될 수 없으면 0으로 처리
    const numericValue = isNaN(value) ? 0 : Number(value);  // value를 숫자로 강제 변환
    setRoomValues((prevValues) => ({
      ...prevValues,
      [buildingNumber]: {
        ...prevValues[buildingNumber],
        [roomNumber]: numericValue,
      },
    }));
  };
  



  // Firestore에 roomValues 저장
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {


    // roomValues의 값을 숫자로 변환
    const updatedRoomValues = Object.entries(roomValues).reduce((acc, [buildingNumber, rooms]) => {
      const updatedRooms = Object.entries(rooms).reduce((roomAcc, [roomNumber, value]) => {
        // 값을 숫자로 변환
        roomAcc[roomNumber] = Number(value) || 0;  // 숫자가 아니면 0으로 설정
        return roomAcc;
      }, {});
      acc[buildingNumber] = updatedRooms;
      return acc;
    }, {});

      // 각 buildingNumber를 Firestore에 저장
      for (const [buildingNumber, rooms] of Object.entries(updatedRoomValues)) {
        await setDoc(doc(db, 'roomValues', buildingNumber), rooms);
      }
      alert('모든 호실의 속성이 저장되었습니다.');
    } catch (error) {
      console.error('Error saving room values:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className='all-settings-container'>
      <h2>전체 호실 속성 수정</h2>
      <form onSubmit={handleSubmit}>
        {buildings.map((building, buildingIndex) => (
          <div key={buildingIndex}>
            <h2>{building.buildingNumber}동</h2>
            {building.lineData.map((line, lineIndex) => (
              <div key={lineIndex} className='line-container'>
                <h4> 라인{line.line}</h4>
                <div className='room-grid'>
                  {Array.from({ length: line.floors }, (_, floor) => {
                    const roomNumber = (floor + 1) * 100 + line.line;
                    return (
                      <React.Fragment key={roomNumber}>
                        <label>{roomNumber} 호:</label>
                        <input
                          type='text'
                          placeholder='dB'
                          value={
                            roomValues[building.buildingNumber]?.[roomNumber] !== undefined
                              ? roomValues[building.buildingNumber][roomNumber]
                              : 0 
                          }
                          onChange={(e) =>
                            handleInputChange(
                              building.buildingNumber,
                              roomNumber,
                              e.target.value
                            )
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ))}
        <button type='submit'>저장하기</button>
      </form>
    </div>
  );
};

export default AllSettingsPage;
