import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  query,
  orderBy
} from 'firebase/firestore';
import db from '../firebase.js'; // Firestore 초기화 설정
import AddBuildingForm from '../components/AddBuildingForm';
import Display from '../components/Display';
import '../css/styles.css';
import logo from '../css/logo.png';
import color from '../css/colorpicker.png';

function Home() {
  const [buildings, setBuildings] = useState([]);
  const [showAddBuildingForm, setShowAddBuildingForm] = useState(false); // 폼을 보여줄지 말지 상태 관리

  // Firestore에서 데이터 가져오기
  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        // Firestore에서 buildingNumber를 기준으로 오름차순 정렬
        const buildingsQuery = query(
          collection(db, 'buildings'),
          orderBy('buildingNumber', 'asc')
        );
        const querySnapshot = await getDocs(buildingsQuery);
        const buildingsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBuildings(buildingsData);
      } catch (error) {
        console.error('Error fetching buildings:', error);
      }
    };

    fetchBuildings();
  }, []);



  // 새로운 건물 추가
  const handleAddBuilding = async (newBuilding) => {
    try {
      const docRef = await addDoc(collection(db, 'buildings'), newBuilding);
      const addedBuilding = { id: docRef.id, ...newBuilding };
      setBuildings([...buildings, addedBuilding]);
      setShowAddBuildingForm(false); // 폼 숨기기
    } catch (error) {
      console.error('Error adding building:', error);
    }
  };

  // 건물 삭제
  const handleDeleteBuilding = async (buildingNumber) => {
    try {
      const buildingToDelete = buildings.find(
        (building) => building.buildingNumber === buildingNumber
      );
      if (buildingToDelete) {
        await deleteDoc(doc(db, 'buildings', buildingToDelete.id));
        const updatedBuildings = buildings.filter(
          (building) => building.buildingNumber !== buildingNumber
        );
        setBuildings(updatedBuildings);
      }
    } catch (error) {
      console.error('Error deleting building:', error);
    }
  };

  const toggleAddBuildingForm = () => {
    setShowAddBuildingForm(!showAddBuildingForm); // 폼 토글
  };

  return (
    <div className='App'>
      <div className='centered-container'>
        <img src={logo} width='150px' alt='Logo' />
        <h1>바닥구조 측정표</h1>
        <div className='custom-divider'></div>
      </div>

      <button onClick={toggleAddBuildingForm}>
        {showAddBuildingForm ? '취소' : '생성하기'}
      </button>
      <div className='custom-divider'></div>

      <img src={color} width='350px' alt='color' />

      {showAddBuildingForm && (
        <AddBuildingForm onAddBuilding={handleAddBuilding} />
      )}

      {/* Display 컴포넌트에 전달 */}
      <Display buildings={buildings} onDeleteBuilding={handleDeleteBuilding} />
    </div>
  );
}

export default Home;
