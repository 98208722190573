import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SettingPage from './pages/SettingPage';
import AllSettingsPage from './pages/AllSettingPage.js';
import Sidebar from './components/sidebar.js';
import './App.css';

// Firebase Firestore 관련 import
import db from './firebase.js';
import { collection, getDocs, addDoc } from 'firebase/firestore';

const App = () => {
  const [buildings, setBuildings] = useState([]);

  // Firestore에서 데이터 불러오기
  const fetchBuildings = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'buildings'));
      const buildingsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBuildings(buildingsArray);
    } catch (error) {
      console.error('Error fetching buildings:', error);
    }
  };

  // Firestore에 새 건물 추가
  const handleAddBuilding = async (newBuilding) => {
    try {
      const docRef = await addDoc(collection(db, 'buildings'), newBuilding);
      setBuildings([...buildings, { id: docRef.id, ...newBuilding }]);
    } catch (error) {
      console.error('Error adding building:', error);
    }
  };

  // 앱 로드 시 Firestore에서 데이터 가져오기
  useEffect(() => {
    fetchBuildings();
  }, []);

  return (
    <div className='App'>
      <Router>
        <Sidebar />

        <Routes>
          {/* Home에 handleAddBuilding 함수를 onAddBuilding prop으로 전달 */}
          <Route
            path='/'
            element={
              <Home buildings={buildings} onAddBuilding={handleAddBuilding} />
            }
          />
          <Route
            path='/setting/:buildingNumber/:roomNumber'
            element={<SettingPage />}
          />
          <Route
            path='/setting'
            element={<AllSettingsPage buildings={buildings} />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
