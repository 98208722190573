import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import db from '../firebase.js';

const Display = ({ buildings, onDeleteBuilding }) => {
  const navigate = useNavigate();
  const [allRoomValues, setAllRoomValues] = useState({});

  // Firestore에서 roomValues 로드
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'roomValues'), (snapshot) => {
      const roomValues = {};
      snapshot.forEach((doc) => {
        roomValues[doc.id] = doc.data();
      });
      setAllRoomValues(roomValues);
    });

    // 컴포넌트가 언마운트될 때 Firestore 구독 해제
    return () => unsubscribe();
  }, []);

        // 방의 색상 설정 로직
    const getRoomColor = (buildingNumber, roomNumber, allRoomValues) => {
        const buildingRooms = allRoomValues[buildingNumber] || {};
        const value = buildingRooms[roomNumber];

        // 값이 없는 경우 흰색 반환
        if (value === undefined || value === null || value === "" || value === 0) {
            return 'white';
    }

        
            
    // dB 값에 따라 색상 매핑
    const dbColorMap = {
      52: '#65C17D', // 초록색
      53: '#97D352',
      54: '#CBE929',
      55: '#FFFF00',
      56: '#FEDA1A',
      57: '#FCB435',
      58: '#FA8F50',
      59: '#F8696B', // 빨강
    };

    // 52 이하의 값은 52와 같은 색, 59 이상은 59와 같은 색
    if (value <= 52) {
      return dbColorMap[52]; // 52와 같은 색
    } else if (value >= 59) {
      return dbColorMap[59]; // 59와 같은 색
    }

    // 52와 59 사이의 값은 매핑된 색상 반환
    return dbColorMap[value] || 'white';
  };

  const sortedBuildings = [...buildings].sort((a, b) => a.buildingNumber - b.buildingNumber);


  return (
    <div id='buildingContainer'>
      {sortedBuildings.map((building, index) => (
        <div key={index} className='building'>
          <h2>{building.buildingNumber}동</h2>
          <button onClick={() => onDeleteBuilding(building.buildingNumber)}>
            삭제
          </button>
          {Array.from(
            { length: Math.max(...building.lineData.map((l) => l.floors)) },
            (_, floor) => (
              <div key={floor} className='floor'>
                {building.lineData.map((line, lineIndex) => {
                  const reverseFloor =
                    building.lineData
                      .map((l) => l.floors)
                      .reduce((a, b) => Math.max(a, b)) - floor;
                  const roomNumber = reverseFloor * 100 + line.line;

                  // lowestFloor가 1이 아니면 해당 라인에서 lowestFloor보다 낮은 층에 있는 방은 회색으로 색칠하고 클릭 불가
                  const isRoomBelowLowestFloor =
                    line.lowestFloor !== 1 && reverseFloor < line.lowestFloor;
                  const roomIsClickable = !isRoomBelowLowestFloor; // lowestFloor보다 낮은 층은 클릭 불가

                  const handleRoomClick = async () => {
                    if (roomIsClickable) {
                      navigate(
                        `/setting/${building.buildingNumber}/${roomNumber}`
                      );
                    }
                  };

                  return (
                    reverseFloor <= line.floors && (
                      <div
                        key={lineIndex}
                        className='room'
                        style={{
                          backgroundColor: isRoomBelowLowestFloor
                            ? 'rgb(49, 49, 49)'
                            : getRoomColor(
                                building.buildingNumber,
                                roomNumber,
                                allRoomValues
                              ), // 회색으로 색칠
                          border: line.hoist
                            ? '2px solid black'
                            : '1px solid gray',
                          cursor: roomIsClickable ? 'pointer' : 'not-allowed', // 클릭 불가일 때 'not-allowed'로 설정
                          width: '60px',
                          margin: '0 5px',
                          display: 'inline-block', // 인라인 블록으로 정렬
                        }}
                        onClick={handleRoomClick}
                      >
                        {roomNumber}
                      </div>
                    )
                  );
                })}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default Display;
